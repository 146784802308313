import { Box, CircularProgress, Container, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Chapter from '../../components/Chapter/Chapter'
import Comment from '../../components/Comments/Comment'
import { toastMessage } from '../../helper/AlertToast'
import instance from '../../helper/ConnectionRequest'
import parse from 'html-react-parser'

const ChapterList = () => {
    const { id } = useParams()
    const [data, setData] = useState('')

    const fetchChapter = async () => {
        await instance({
            method: 'GET',
            url: `/chapter.php?id=${id}`,
        }).then((res) => {
            setData(res.data.chapterData)
        }).catch(err => {
            console.log(err)
            toastMessage(err.response.data.message, "error")
        })
    }
    useEffect(() => {
        fetchChapter()
    }, [])

    return (
        <>
            <Container
                sx={{
                    width: "70%",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    textAlign: "start",
                    padding: "1rem",
                    // marginTop: "1rem",
                    marginBottom: "2.5rem",
                }}
            >
                {
                    data && Object.keys(data).length > 0 ? (
                        <>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', marginBottom: '3rem' }}>
                                <img src={data.story.coverPic} alt={data.story.title} style={{ height: '100%' }} />
                            </div>
                            <Typography variant="h4">{`${data.story.title[0].toUpperCase()}${data.story.title.slice(1)}`}</Typography>
                            <Typography variant="h5">Chapter {data.chapterNumber}</Typography>
                            {/* <Typography variant="h6">Sub heading</Typography> */}
                            <Typography>{parse(data.description)}</Typography>
                        </>) : <CircularProgress />
                }
            </Container>
            <Container sx={{ width: "70%", marginBottom: "2rem" }}>
                <Box style={{ border: "1px solid red" }}>
                    <Comment />
                </Box>
            </Container>
        </>
    )
}

export default ChapterList