import { Button, CircularProgress, Container, FormControl, Grid, MenuItem, Select, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useState } from "react";
import instance from "../../helper/ConnectionRequest";
import { storage } from "../../config/Firebase"
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { useEffect } from "react";
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import { toastMessage } from "../../helper/AlertToast";
import ShowMessage from "../../helper/Alert";
// import toast from "react-hot-toast";
// import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
  ],
};

const AddStory = () => {

  const [percent, setPercent] = useState(0);
  const { quill, quillRef } = useQuill({ modules });

  const [Values, setValues] = useState({
    title: '',
    description: '',
    coverPic: '',
    typeCategory: ''
  });
  const [imageLoading, setimageLoading] = useState(false);

  const { title, description, coverPic, typeCategory } = Values

  if (quill) {
    quill.on('text-change', () => {
      setValues({ ...Values, description: quillRef.current.firstChild.innerHTML })
    });
  }


  const handleSubmit = async (e) => {
    e.preventDefault()
    await instance({
      method: 'POST',
      url: '/api/v1/admin/add-story',
      data: Values
    }).then((res) => {
      setValues({
        title: '',
        description: '',
        coverPic: '',
      })
      toastMessage(res.data.message, "success")
    }).catch(err => {
      console.log("lp", err.response.data.message)
      toastMessage(err.response.data.message, "error")
    })
  };

  // const postDetails = (profilepic, e) => {
  //   e.preventDefault()
  //   const file = profilepic
  //   console.log("object");
  //   const Reader = new FileReader();
  //   Reader.readAsDataURL(file);
  //   console.log("first")
  //   Reader.onload = () => {
  //     if (Reader.readyState === 2) {
  //       setValues({ coverPic: data.url })
  //     }
  //   }
  //   console.log("lp");
  //   const data = new FormData()
  //   data.append("file", profilepic)
  //   data.append("upload_preset", "meeting_app")
  //   data.append("cloud_name", "wolf-shubham")
  //   fetch("https://api.cloudinary.com/v1_1/wolf-shubham/image/upload", {
  //     method: "post",
  //     body: data
  //   })
  //     .then(res => res.json())
  //     .then(data => {
  //       console.log(data)
  //       setValues({ coverPic: data.url })
  //     })
  //     .catch(err => {
  //       console.log(err)
  //     })
  // }

  // function handleChange1(event) {
  //   setFile(event.target.files[0]);
  // }

  const handleChange = async (e) => {
    const coverPic = e.target.files[0]
    if (e.target.name == 'coverPic') {
      setimageLoading(true);

      const storageRef = ref(storage, `/files/${coverPic.name}`)
      const uploadTask = uploadBytesResumable(storageRef, coverPic);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          // update progress
          setPercent(percent);
        },
        (err) => console.log(err),
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setValues({ ...Values, coverPic: url })
            setimageLoading(false)
          });
        }
      );
    } else {
      setValues({ ...Values, [e.target.name]: e.target.value });
    }
  };

  const options = [
    { value: "ORIGINAL", label: "ORIGINALS" },
    { value: "FAN-FICTION", label: "FAN-FICTION" },
  ];


  return (
    <>
      <Container>
        <h1 style={{ marginTop: '15px' }}>Add Story</h1>
        {/* <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', border: '1px solid red' }}> */}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 3 }}>
            <Grid item xs={8}>
              <TextField
                type="text"
                fullWidth
                onChange={(e) => setValues({ ...Values, title: e.target.value })}
                value={title}
                name="title"
                variant="outlined"
                placeholder="Title of story"
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                select
                fullWidth
                value={typeCategory}
                name="typeCategory"
                label='Category Type'
                onChange={(e) => setValues({ ...Values, typeCategory: e.target.value })}
              >
                {options.map((item, index) => (
                  <MenuItem value={item.value} key={index}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={8}>
              <TextField
                type="text"
                // onChange={(e) => setValues({ ...Values, description: quillRef.current.firstChild.innerHTML })}
                value={title}
                name="title"
                variant="outlined"
                // placeholder="Title of story"
                ref={quillRef}
                sx={{ width: '100%', height: '250px' }}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                onChange={handleChange}
                InputProps={{
                  disableUnderline: true
                }}
                fullWidth
                type='file'
                id="standard-basic"
                label="Image"
                name="coverPic"
                variant="standard"
                style={{ border: '0' }}
              />
            </Grid>
            <Grid item xs={8}>
              {imageLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress />
                </Box>
              ) : null}
              {Values.coverPic ? 'uploaded !' : null}
            </Grid>
            <Grid item xs={8}>
              <Button type="submit" variant="contained" sx={{ width: '200px' }}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
        {/* </Box> */}
        {/* <div style={{ width: 500, height: 300 }}>
          <div ref={quillRef} />
        </div> */}
      </Container>
    </>
  );
};

export default AddStory;
