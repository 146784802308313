import { Button } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import parse from 'html-react-parser'
import './Chapter.css'

const Chapter = ({ data }) => {
    const { chaptersList } = data
    const navigate = useNavigate()
    const arr = [5, 4, 3, 2, 1]
    // const chapterData = data.data.chaptersList
    const handlelink = () => {
        let url = '/story-name/:id'
        navigate(url)
    }
    return (
        <>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'start', padding: '2rem' }}>
                <h2>List of Chapters</h2>
                {chaptersList &&
                    chaptersList.length > 0 ? (
                    chaptersList.map((a) => (
                        <div style={{ margin: '1rem 0' }} className='chapterContainer'>
                            <a href={`/${data.title.replace(/[ ,?]/g, "")}/chapter-${data.chapterNumber}/${a._id}`} style={{display:'block'}} className='no-dec'>
                                <h3>Chapter {a.chapterNumber}</h3>
                                <p>{parse(`${(a.description).slice(0, 100)}...`)}</p>
                                <Button variant='contained' onClick={(e) => navigate(`/${data.title.replace(/[ ,?]/g, "")}/chapter-${data.chapterNumber}/${a._id}`)}>Read</Button>
                            </a>
                        </div>
                    ))) : "No Chapters Available."
                }
            </div>
        </>
    )
}

export default Chapter