import { Button, CircularProgress, FormControl, Grid, TextField } from '@mui/material';
import { Box, Container } from '@mui/system';
import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { toastMessage } from '../../helper/AlertToast';
import instance from '../../helper/ConnectionRequest';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
  ],
};

const AddChapter = () => {
  const { id } = useParams()
  const { quill, quillRef } = useQuill({ modules });

  const navigate = useNavigate()

  const [Values, setValues] = useState({
    description: '',
    coverPic: '',
    chapterNumber: ''
  });
  const [imageLoading, setimageLoading] = useState(false);

  const { title, description, chapterNumber, coverPic } = Values

  if (quill) {
    quill.on('text-change', () => {
      setValues({ ...Values, description: quillRef.current.firstChild.innerHTML })
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log(Values);
    await instance({
      method: 'POST',
      url: `/api/v1/admin/add-chapter/${id}`,
      data: Values
    }).then((res) => {
      setValues({
        title: '',
        description: '',
        coverPic: '',
      })
      toastMessage(res.data.message, "success")
      navigate('/tltales/admin')
    }).catch(err => {
      console.log(err)
      toastMessage(err.response.data.message, "error")
    })
  };

  return (
    <Container>
      <h1 style={{ marginTop: '15px' }}>Add Chapter</h1>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 3 }}>
          <Grid item xs={8}>
            <TextField
              onChange={(e) => setValues({ ...Values, chapterNumber: e.target.value })}
              value={chapterNumber}
              fullWidth
              type={"number"}
              name="chapterNumber"
              variant="outlined"
              placeholder="Chapter Number"
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              type="text"
              value={description}
              name="description"
              variant="outlined"
              ref={quillRef}
              sx={{ width: '100%', height: '250px' }}
            />
          </Grid>
          <Grid item xs={8}>
            {imageLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            ) : null}
            {Values.coverPic ? 'uploaded !' : null}
          </Grid>
          <Grid item xs={8}>
            <Button type="submit" variant="contained" sx={{ width: '200px' }}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container >
  )
}

export default AddChapter