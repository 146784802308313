import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, FormControl, Grid, TextField, Typography } from "@mui/material";
import { toastMessage } from '../../helper/AlertToast';
import { Container } from "@mui/system";
import instance from '../../helper/ConnectionRequest';
import UserStoryCard from '../StoryCard/UserStoryCard';

const AllStories = () => {
  const [data, setData] = useState([])

  const fetchStoryCollection = async () => {
    await instance({
      method: 'GET',
      url: `/stories.php`,
    }).then((res) => {
      setData(res.data.data)
    }).catch(err => {
      console.log(err)
      toastMessage(err.response.data.message, "error")
    })
  }

  useEffect(() => {
    fetchStoryCollection()
  }, [])
  return (
    <>
      <Grid container sx={{ ml: 2.2 }}>
        {
          data && data.length > 0 ? (
            data.map((d) => (
              <Grid item sm={12} md={6} lg={4} sx={{ p: 2 }}>
                <UserStoryCard data={d} />
              </Grid>
            ))
          ) :
            <div style={{ width: '100%', padding: '5rem' }}>
              <CircularProgress />
            </div>
        }
      </Grid>
    </>
  )
}

export default AllStories