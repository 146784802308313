import React from 'react'
import { Button, Card, CardMedia } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser'


const goTo = (url) => {
    console.log(url);
   //window.location.href = url;
}

const UserStoryCard = ({ data }) => {
    const navigate = useNavigate()
    return (
        <>
            <Card sx={{ width: '350px', height: '550px', cursor:'pointer'}}>
            <a style={{display:'block'}} href={`/${data.title.replace(/[ ,?]/g, "-")}/${data._id}`} className='no-dec'>
                    <CardMedia
                        component="img"
                        alt="Cover Pic"
                        width='250px'
                        height='350px'
                        image={data.coverPic}
                    />
                    {/* <img src={data.coverPic && data.coverPic} alt="Cover Pic" style={{ width: '200px' }} /> */}
                    <div style={{ height: '150px', padding: '10px', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }}>
                        <h3>{`${data.title[0].toUpperCase()}${data.title.slice(1)}`}</h3>
                        <p>{parse(`${(data.description).slice(0, 100)}...`)}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <Button variant='contained' onClick={(e) => navigate(`/${data.title.replace(/[ ,?]/g, "-")}/${data._id}`)}>Go to story</Button>
                    </div>
                    </a>
            </Card>
            
        </>
    )
}

export default UserStoryCard