import React from "react";
import patreon from "../../assets/patreon.png";
import paypal from "../../assets/paypal.png";

const imgstyle = {
  width: "150px",
  borderRadius: "50%",
};

const Support = () => {
  return (
    <>
      <div className="contactUsContainer"
        style={{
          width: "100%",
          
          
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <a
          href=""
          target="_blank"
          rel="noreferrer"
        >
          <img src={patreon} alt="Patreon" style={imgstyle} />
        </a>
        <img src={paypal} alt="Patreon" style={imgstyle} />
      </div>
    </>
  );
};

export default Support;
