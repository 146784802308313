import { Box, Typography } from "@mui/material";
import React from "react";
import logo from "../../assets/pngegg.png";
import SearchIcon from "@mui/icons-material/Search";
import "./Navbar.css";
import { Link } from "react-router-dom";
const openMenu = () => {
    document.getElementById('navLink').classList.toggle('active');
}

const closeMenu = () => {
    document.getElementById('navLink').classList.toggle('active');
}
const Navbar = () => {
  return (
    <>
      <div className="navbar">
        <div className="navLogo">
          <img src={logo} alt="logo" style={{ width: "52px" }} />
          <div
            style={{
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginLeft: "1rem",
            }}
          >
            <h2>The Lonely Tales</h2>
            <h5>A place to relieve your boredom</h5>
          </div>
        </div>
        <div className="navLink" id="navLink">
        <div className="mobile_burger_menu_close" id="mobile_burger_close_menu" onClick={closeMenu}>
            x
        </div>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <Typography
              variant="h5"
              noWrap
              sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
              style={{
                listStyleType: "none",
                textDecoration: "none",
                color: "white",
              }}
            >
              Home
            </Typography>
          </Link>
          <Link to="/story/collection" style={{ textDecoration: 'none' }}>
            <Typography
              variant="h5"
              noWrap
              sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
              style={{
                listStyleType: "none",
                textDecoration: "none",
                color: "white",
              }}
            >
              Stories
            </Typography>
          </Link>
          <Link to="/support" style={{ textDecoration: 'none' }}>
            <Typography
              variant="h5"
              noWrap
              sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
              style={{
                listStyleType: "none",
                textDecoration: "none",
                color: "white",
              }}
            >
              Support Us
            </Typography>
          </Link>
          <Link to="/joinus" style={{ textDecoration: 'none' }}>
            <Typography
              variant="h5"
              noWrap
              sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
              style={{
                listStyleType: "none",
                textDecoration: "none",
                color: "white",
              }}
            >
              Join Us
            </Typography>
          </Link>
          <div className="searchContainer">
            <input type="text" />
            <SearchIcon
              style={{ position: "absolute",  color: "white" }}
              
            />
          </div>
        </div>
        <div className="mobile_burger" id="mobile_burger" onClick={openMenu}>
            <span></span>
            <span></span>
            <span></span>
        </div>
        {/* <div className="navLink">
          <ul className="homeLinks">
            <Link to="/" className="linkTag">
              <li
                style={{
                  color: "#396EB0",
                }}
              >
                <span>Home</span>
              </li>
            </Link>
            <Link to="/" className="linkTag">
              <li
                style={{
                  color: "#396EB0",
                }}
              >
                <span>Home 1</span>
              </li>
            </Link>
            <Link to="/" className="linkTag">
              <li
                style={{
                  color: "#396EB0",
                }}
              >
                <span>Home 2</span>
              </li>
            </Link>
            <Link to="/" className="linkTag">
              <li
                style={{
                  color: "#396EB0",
                }}
              >
                <span>Home 3</span>
              </li>
            </Link>
          </ul>
        </div> */}
      </div>
     
    </>
   
   
    
  );
  
};




export default Navbar;
