import React, { useEffect, useState } from "react";
import { toastMessage } from "../../helper/AlertToast";
import { Box, Button, CircularProgress, FormControl, Grid, TextField, Typography } from "@mui/material";
import instance from "../../helper/ConnectionRequest";
import List from "../List/List";
import UserStoryCard from "../StoryCard/UserStoryCard";

const FanFiction = () => {
  const [data, setData] = useState([])

  const fetchStoryCollection = async () => {
    await instance({
      method: 'GET',
      url: `/stories.php?type=${'Fanfiction'}`,
    }).then((res) => {
      setData(res.data.data)
    }).catch(err => {
      console.log(err)
      toastMessage(err.response.data.message, "error")
    })
  }

  useEffect(() => {
    fetchStoryCollection()
  }, [])
  return (
    <>
      <h1>Fan Fiction</h1>
      <Grid container sx={{ ml: 2.2 }}>
        {
          data && data.length > 0 ? (
            data.map((d) => (
              <Grid item sm={12} md={6} lg={4} sx={{ p: 2 }}>
                <UserStoryCard data={d} />
              </Grid>
            ))
          ) :
            <div style={{ width: '100%', padding: '5rem' }}>
              <CircularProgress />
            </div>
        }
      </Grid>
    </>
  );
};

export default FanFiction;
