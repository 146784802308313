import { Button } from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Chapter from "../../components/Chapter/Chapter";
import { toastMessage } from "../../helper/AlertToast";
import instance from "../../helper/ConnectionRequest";
import parse from 'html-react-parser'

const Story = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [data, setData] = useState('')
  const [chapterData, setChapterData] = useState('')

  const fetchStory = async () => {
    await instance({
      method: 'GET',
      url: `/story.php?id=${id}`,
    }).then((res) => {
      console.log("ko", res.data.storyData)
      setData(res.data.storyData)
    }).catch(err => {
      console.log(err)
      toastMessage(err.response.data.message, "error")
    })
  }

  const fetchFirstChapter = async () => {
    await instance({
      method: 'GET',
      url: `/first_chapter.php?story_id=${id}`,
    }).then((res) => {
      console.log("ko", res.data.chapterData)
      setChapterData(res.data.chapterData)
    }).catch(err => {
      console.log(err)
      toastMessage(err.response.data.message, "error")
    })
  }

  useEffect(() => {
    fetchStory()
  }, [])

  useEffect(() => {
    fetchFirstChapter()
  }, [])

  return (
    <>
      <Container sx={{ width: '70%' }}>
        <div class="story_page_container" style={{ marginTop: '2rem',  justifyContent: 'center' }}>
          <div class="story_image_section" style={{  padding: '2rem' }}>
            <img src={data.coverPic} alt={data.title} style={{ height: "100%", width: '100%', display: 'flex', justifyContent: 'space-evenly' }} />
          </div>
          <div class="story_body_section" style={{ textAlign: 'start', padding: '2rem', height: '100%', lineHeight: '3rem' }}>
            <h2>{data.title}</h2>
            <h3>{data.typeCategory}</h3>
            <h3>Genre : </h3>
            <Button
              variant="contained"
              onClick={(e) => navigate(`/${data.title.replace(/[ ,?]/g, "")}/chapter-${chapterData.chapterNumber}/${chapterData._id}`)}
            >
              Start reading
            </Button>
          </div>
        </div>
        <div style={{ textAlign: 'start', paddingLeft: '2rem' }}>
          <h3>Synopsis</h3>
          <p style={{ height: '50%', overflow: 'hidden', }}>{parse(`${(data.description)}`)}</p>
        </div>
        <Chapter data={data} />
      </Container>
    </>
  );
};

export default Story;
