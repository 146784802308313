import { Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser'

const StoryCard = ({ data }) => {
    const navigate = useNavigate()
    return (
        <>
            {/* <Container> */}
            {/* <Box sx={{ mt: 3, p: 3, border: '2px solid darkgrey' }}>
                    <h1>{data.title}</h1>
                    <div>{parse(`${data.description}`)}</div>
                    <img src={data.coverPic} alt="Story Cover pic" style={{ width: '500px' }} />
                    <Button variant='contained' onClick={(e) => navigate(`/admin/add-chapter/${data._id}`)}>Add Chapters</Button>
                </Box> */}
            {/* <Box sx={{ mt: 3, p: 3, border: '2px solid darkgrey' }}> */}
            <Card sx={{ width: '350px', height: '550px' }}>
                <CardMedia
                    component="img"
                    alt="Cover Image"
                    width='250px'
                    height='350px'
                    image={data.coverPic}
                />
                <div style={{ height: '150px', padding: '10px', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }}>
                    <h3>{`${data.title[0].toUpperCase()}${data.title.slice(1)}`}</h3>
                    <p>{parse(`${(data.description).slice(0, 100)}...`)}</p>
                </div>
                {/* <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                        {data.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {parse(`${(data.description).slice(0, 100)}...`)}
                    </Typography>
                </CardContent> */}
                {/* <CardActions>
                    <Button size="small">Share</Button>
                    <Button size="small">Learn More</Button>
                </CardActions> */}
                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <Button variant='contained' onClick={(e) => navigate(`/${data.title.replace(/[ ,?]/g, "-")}/${data._id}`)}>Go to story</Button>
                    <Button variant='contained' onClick={(e) => navigate(`/admin/add-chapter/${data._id}`)}>Add Chapters</Button>
                </div>
            </Card>
        </>
    )
}

export default StoryCard