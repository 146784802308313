import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import hp from "../../assets/hp.jpg";
import "./ShortStory.css";
import parse from 'html-react-parser'

const ShortStory = ({ data }) => {
  console.log("jk", data)
  return (
    <>
      <a href={`/${data.story.title.replace(/[ ,?]/g, "")}/chapter-${data.chapterNumber}/${data._id}`} className="storySnap">
        <div className="short_story_image" style={{  }}>
          {/* change for link to story page instead of chapter. */}
          <Link to={`/${data.story.title.replace(/[ ,?]/g, "")}/chapter-${data.chapterNumber}/${data._id}`}>
            <img
              src={data.story.coverPic}
              alt={data.story.title}
              style={{ width: "150px", marginRight: "5vh" }}
            />
          </Link>
        </div>
        <div className="short_story_text"
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            // border: "1px solid red",
            padding: "0.5rem ",
           
           
            paddingLeft: '2rem'
            // marginLeft: '5rem'
          }}
        >
          <h3>{`${data.story.title[0].toUpperCase()}${data.story.title.slice(1)}`}</h3>
          <h4>Chapter {data.chapterNumber}</h4>
          <p class="continue_reading_container">{parse(`${(data.description).slice(0, 100)}...`)}{" "}
            <Link to={`/${data.story.title.replace(/[ ,?]/g, "")}/chapter-${data.chapterNumber}/${data._id}`} style={{ textDecoration: 'none', color: '#1976d2' }}>
              <span className="continue-reading" style={{  fontWeight: 'bolder', marginTop: '15px' }}>continue reading...</span>
            </Link>
          </p>
          {/* <div
            style={{
              width: "90%",
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
            }}
          >
            <Link to="/story-name/id" style={{ textDecoration: 'none' }}>
              <Button variant="contained" >Continue reading</Button>
            </Link>
          </div> */}
        </div>
      </a>
    </>
  );
};

export default ShortStory;
