import React from "react";
import "./App.css";
import {
  signInWithFacebook,
  signInWithGoogle,
} from "../src/helper/FirebaseAuth";
import Navbar from "./components/Navbar/Navbar";
import { Route, Routes } from "react-router-dom";
import Error from "./pages/Error/Error";
import Home from "./pages/Home/Home";
import Footer from "./components/Footer/Footer";
import Story from "./pages/Story/Story";
import Collection from "./pages/Collections/Collection";
import Joinus from "./pages/JoinUs/Joinus";
import Support from "./pages/Support/Support";
import AddStory from "./pages/AddStory/AddStory";
import Admin from "./pages/Admin/Admin";
import AddChapter from "./pages/AddStory/AddChapter";
import Chapter from "./pages/Chapter/Chapter";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Toaster />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/:title/:id" element={<Story />} />
        <Route path="/story/collection" element={<Collection />} />
        <Route path="/joinus" element={<Joinus />} />
        <Route path="/support" element={<Support />} />
        <Route path="/tltales/admin" element={<Admin />} />
        <Route path="/admin/add-story" element={<AddStory />} />
        <Route path="/admin/add-chapter/:id" element={<AddChapter />} />
        <Route path="/:storyName/:chapter/:id" element={<Chapter />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </div>
   
    
  );
  
  
}



export default App;
