import React from "react";
import { Link } from "react-router-dom";

const SocialLink = ({ image, url }) => {
  return (
    <>
      <div class="social_link_each_container">
        <a href={url} target="_blank" rel="noreferrer" className="social_link_a">
          <img src={image} alt="" className="socialLinkImg"  />
        </a>
      </div>
    </>
  );
};

export default SocialLink;
