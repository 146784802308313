import React from "react";

const Error = () => {
  return (
    <>
      <h1>404 Error. Page Not Found !</h1>
    </>
  );
};

export default Error;
