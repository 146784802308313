import { Box, Button, CircularProgress, FormControl, Grid, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import StoryCard from "../../components/StoryCard/StoryCard";
import instance from "../../helper/ConnectionRequest";

const Admin = () => {

  const [loading, setloading] = React.useState(true);
  const [data, setData] = useState([])

  const navigate = useNavigate()

  const handleSubmit = {};
  const handleChange = {};

  const getStories = async () => {
    setloading(true);
    try {
      await instance({
        method: 'GET',
        url: `/api/v1/admin/stories-list`
      }).then((res) => {
        setData(res.data.data)
        // settotalPages(res.data.totalPages);
        // setcnt(res.data.count);
        // setscreen(res.data?.allScrens);
        // setallscreen(res.data.allScrens);
        setloading(false);
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getStories()
  }, [])

  return (
    <>
      <Container>
        <h1>Admin Page</h1>
        <Link to="/admin/add-story" style={{ textDecoration: 'none' }}>
          <Button variant='contained'>
            Add Story
          </Button>
        </Link>
        <h2>Stories list</h2>
        <Grid container sx={{ ml: 2.2 }}>
          {
            data.length > 0 ? (
              (
                data.map((d) => (
                  <>
                    <Grid item sm={12} md={6} lg={4} sx={{ p: 2 }}>
                      <StoryCard data={d} />
                    </Grid>
                  </>
                ))
              )
            ) : <CircularProgress />
          }
        </Grid>
      </Container>
    </>
  );
};

export default Admin;
