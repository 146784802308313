import React from "react";

const Comment = () => {
  return (
    <>
      <h2>Comments</h2>
    </>
  );
};

export default Comment;
