import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDQ9hydsh0WNS1JGje-o4NC0MoSIgF5k1Y",
  authDomain: "lonely-tales.firebaseapp.com",
  projectId: "lonely-tales",
  storageBucket: "lonely-tales.appspot.com",
  messagingSenderId: "753005619967",
  appId: "1:753005619967:web:8db9e28ea6686776277aca",
  measurementId: "G-DTF5L08DZR",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const storage = getStorage(app);
