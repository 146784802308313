import { CircularProgress, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Article from "../../components/Article/Article";
import CarouselBar from "../../components/Carousel/CarouselBar";
import List from "../../components/List/List";
import ShortStory from "../../components/StorySnap/ShortStory";
import Upcoming from "../../components/Upcoming/Upcoming";
import UserReview from "../../components/UserReviews/UserReview";
import { toastMessage } from "../../helper/AlertToast";
import instance from "../../helper/ConnectionRequest";


const Home = () => {
  const [data, setData] = useState('')

  const fetchLatestChapters = async () => {
    await instance({
      method: 'GET',
      url: `/recent.php`,
    }).then((res) => {
      setData(res.data.chapterData)
    }).catch(err => {
      console.log(err)
      toastMessage(err.response.data.message, "error")
    })
  }
  useEffect(() => {
    fetchLatestChapters()
  }, [])
  
  
  return (
    <>
      <Article />
      <Container sx={{ width: "70%" }}>
        <Typography
          variant="h4"
          style={{
            display: "flex",
            flex: "start",
            padding: "1rem",
            marginTop: '7.5rem'
          }}
        >
          Recent Stories
        </Typography>
        {
          data && data.length > 0 ?
            (
              data.map((d) => (
                <ShortStory data={d} />
              ))
            ) : <CircularProgress />
        }
        {<><br /><br /></>}

        {/* <ShortStory />
        <ShortStory />
        <ShortStory /> */}
        {/* <Upcoming />
        <UserReview />
        <List /> */}
      </Container>
      {/* <CarouselBar /> */}
    </>
  );
};

export default Home;
