import { Box, Button } from "@mui/material";
import { Container } from "@mui/system";
import React, { useState } from "react";
import FanFiction from "../../components/Fan-Fiction/FanFiction";
import AllStories from "../../components/All-Stories/AllStories";
import Originals from "../../components/Originals/Originals";

const Collection = () => {
  const [name, setName] = useState("all");
  const handleChange = (e, val) => {
    e.preventDefault();
    setName(val);
  };
  return (
    <>
      {/* <h2>Collection</h2> */}
      <Container>
        <Box
          style={{
            // width: "60%",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            margin: '2.5rem'
          }}
        >
          <Button
            onClick={(e) => handleChange(e, "all")}
            style={name === "all" ? { backgroundColor: '#1976d2', color: '#ffffff' } : { backgroundColor: 'transparent', border: '1px solid' }}>
            All
          </Button>
          <Button
            onClick={(e) => handleChange(e, "fan")}
            style={name === "fan" ? { backgroundColor: '#1976d2', color: '#ffffff' } : { backgroundColor: 'transparent', border: '1px solid' }}
          >
            Fan-Fictions
          </Button>
          <Button
            onClick={(e) => handleChange(e, "original")}
            style={name === "original" ? { backgroundColor: '#1976d2', color: '#ffffff' } : { backgroundColor: 'transparent', border: '1px solid' }}
          >
            Originals
          </Button>
        </Box>
        <Box sx={{ marginBottom: '2rem' }}>
          {name === "all" ? (
            <AllStories />
          ) : name === "fan" ? (
            <FanFiction />
          ) : name === "original" ? (
            <Originals />
          ) : null}
        </Box>
      </Container>
    </>
  );
};

export default Collection;
