import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <h3>The Lonely tales © 2022 </h3>
      </div>
    </>
  );
  
  
  
};

export default Footer;
