import axios from "axios";

//const url = "http://localhost:8008/";
// const url ="https://public-turkeys-sneeze-49-36-221-112.loca.lt"
//const url = "http://65.1.34.185/";
//const url = "http://localhost/lonely-tales-api/";
const url = "https://lonelyapi.clusterinfos.com/";

const instance = axios.create({
  baseURL: url,
  headers: { "X-Requested-With": "XMLHttpRequest" },
});

export default instance;
