import {
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "../config/Firebase";

const provider = new GoogleAuthProvider();
const fbProvider = new FacebookAuthProvider();

export const signInWithGoogle = () => {
  signInWithPopup(auth, provider)
    .then((res) => {
      console.log(res);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const signInWithFacebook = () => {
  signInWithPopup(auth, fbProvider)
    .then((res) => {
      console.log(res);
    })
    .catch((error) => {
      console.log(error);
    });
};
export const signInWithEmailPassword = (email, password) => {
  signInWithEmailAndPassword(auth, email, password);
};

export const registerWithEmailPassword = (email, password) => {
  createUserWithEmailAndPassword(auth, email, password);
};

