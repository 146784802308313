import { Button } from "@mui/material";
import React from "react";
import Alex_Ashbridge from "../../assets/Alex_Ashbridge.png";
import Banner from "../../assets/banner.png";
import "./Article.css";
import Slider from "react-slick";

const Article = () => {
    
    
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
  
  return (
    <>
       <Slider {...settings}>
            <div className="articleHead">
              <div className="imgBlock">
                <img
                  src={Banner}
                  alt="book cover"
                  
                />
              </div>
              
            </div>
            
            
            
            
         </Slider>
    </>
  );
};

export default Article;
