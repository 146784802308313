import React from "react";
import SocialLink from "../../components/SocialLink";
import Facebook from "../../assets/facebook.png";
import Twitter from "../../assets/twitter.png";
import Discord from "../../assets/discord.png";
import Instagram from "../../assets/instagram.png";
import Reddit from "../../assets/reddit.png";

const url = {
  facebook: "klklklklkl",
  discord: "https://discord.com/invite/fZyTGxGjVQ",
  instagram: "https://www.instagram.com/thelonelyguy17/",
};

const Joinus = () => {
  return (
    <>
      <div class="joinUsContainer"
        style={{
          
        
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
      <div class="social_media_container">
        <SocialLink image={Facebook} url={url.facebook} />
        <SocialLink image={Instagram} url={url.instagram} />
    </div>
    <div class="social_media_container">
        <SocialLink image={Discord} url={url.discord} />
        <SocialLink image={Twitter} />
    </div>
     <div class="social_media_container">
        <SocialLink image={Reddit} />
        </div>
      </div>
    </>
  );
};

export default Joinus;
